import React from "react"
import { HeaderVariants } from "../../components/header"
import SEO from "../../components/seo"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import classes from "./post.module.css"
import FacebookIcon from "../../images/facebook.inline.svg"
import TwitterIcon from "../../images/twitter.inline.svg"
import header from "../../images/uidesigner-header.png"

const Post = props => (
  <HomePageLayout headerVariant={HeaderVariants.dark} pageTitle="Blog">
    <SEO title="Blog" />
    <div className={classes.header}>
      <img src={header} alt="Post Image" className={classes.headerImage} />
      <div className={classes.headerContent}>
        <p className={classes.headerContentText}>
          What skills do you need to be a UI/UX designer
        </p>
      </div>
    </div>
    <div className={classes.container}>
      {/*<Link to="/blog" className={classes.link}>*/}
      {/*  <LeftIcon fill="#333" width="15px" />*/}
      {/*  <span className={classes.linkText}>Back</span>*/}
      {/*</Link>*/}
      <div className={classes.postContentGrid}>
        <div>
          <p className={classes.sharePostHeader}>Share Post</p>
          <a
            className={classes.sharePostLink}
            href={`https://twitter.com/intent/tweet?text=www.prunedge.com${props.location.pathname}`}
            target="_blank"
          >
            <TwitterIcon height="18px" />
            <span className={classes.sharePostLinkText}>Tweet Post</span>
          </a>
          <a
            className={classes.sharePostLink}
            href={`http://www.facebook.com/sharer.php?u=www.prunedge.com${props.location.pathname}`}
            target="_blank"
          >
            <FacebookIcon height="18px" />
            <span className={classes.sharePostLinkText}>Facebook Post</span>
          </a>
        </div>
        <div className={classes.postContentWrapper}>
          <h1 className={classes.postHeader}>Becoming a developer</h1>
          <div className={classes.postDetails}>
            <img
              src="https://randomuser.me/api/portraits/women/88.jpg"
              alt="Author Avatar"
              className={classes.postDetailsImage}
            />
            <div className={classes.postDetailsText}>
              <p className={classes.postDetailsAuthor}>Tiwa Ijaware</p>
              <p className={classes.postDetailsDate}>Nov 15, 2019</p>
            </div>
          </div>
          <div className={classes.postContent}>
            <p>
              Ideally, a UI/UX Designer should have some certain skill set to be
              considered a good designer. People call themselves designers
              without really understanding the details of good design. I have
              listed 7 skills you might want to consider before calling yourself
              a good UI/UX Designer.
            </p>
            <h3 className={classes.postContentHeader}>
              Be good at communicating
            </h3>
            <p>
              As a designer, you should be able to communicate your thought
              process properly with your managers and stakeholders. Imagine
              starting a project with no existing communication channel. You
              will end up designing a product the client will not be happy with.
            </p>
            <p>
              The importance of communication can not be overemphasized, as this
              is how you get feedback from stakeholders and ensure you are
              building what they want and not just your thoughts.
            </p>
            <h3 className={classes.postContentHeader}>Empathy</h3>
            <p>
              Another skill every designer should have under their belt is the
              ability to connect a product to potential users on an emotional
              level-empathy.
            </p>
            <p>
              Designers should be empathetic towards the target audience, i.e
              not building a product that suits your lifestyle (except you are
              the only one using the product) but designing a product that suits
              the lifestyle of your target audience(They are the ones you are
              selling to)
            </p>
            <h3 className={classes.postContentHeader}>Be organized</h3>
            <p>
              Last year, I had a task to design a mobile application. The
              application required sending out questionnaires to the users and
              also attending meetings with stakeholders. The reviews were
              received besides documenting key points in each of the agreement
              with the stakeholders. Nevertheless, the feedback was inadequately
              organized which slowed down the design process. Organizing reviews
              cannot be over emphasized as it helps to speed up the design
              process.
            </p>
            <h3 className={classes.postContentHeader}>User research</h3>
            <p>
              As a designer, it is really important to carry out research as it
              is the strength of each project. The idea following this report is
              that, without User Research, you would not understand your
              audience's wants and goals adequately. But if proper research were
              carried out on the target audience, you will understand them
              properly and also create a more reliable product to cater to their
              needs. Hence, User Research is a fundamental skill to possess as a
              UX designer.
            </p>
            <p>
              <em>“User Research is the strength of each project”</em>
            </p>
            <h3 className={classes.postContentHeader}>Visual design</h3>
            <p>
              As a designer, you should be proficient in designing aesthetically
              pleasing user interfaces that are easy to use (exceptional
              functionality). Simplicity is the ultimate sophistication.
            </p>
            <h3 className={classes.postContentHeader}>
              Wireframing & Prototyping
            </h3>
            <p>
              Wireframing & Prototyping is a crucial component of UI/UX design.
              They allow UX designers to quickly communicate their ideas with
              team members, stakeholders, and target users. Wireframes could be
              a simple black and white post designed, or sketch on a wireframing
              tool and could be designing a high-fidelity design mock-up so that
              your team members, stakeholders or target users could see the
              bigger picture of what message you are passing without difficulty.
            </p>
            <h3 className={classes.postContentHeader}>
              A little coding experience
            </h3>
            <p>
              You don’t necessarily need to learn how to code, but it is
              important as a UI/UX designer to know how codes work, so you don’t
              design what is impossible (extremely difficult) to implement for
              developers. That’s why it is always essential to brainstorm
              together with the developers for ease of communication because two
              heads are better than one, right?
            </p>
            <p>
              Basically, these are some of the major skills to have as a UI/UX
              designer. With these skills, it would be seamless for a UI/UX
              designer to create a useful, visually pleasing, and functional
              product.
            </p>
          </div>
        </div>
      </div>
    </div>
  </HomePageLayout>
)

export default Post
